import React from 'react';
import {Form, Select, Input, Button, message} from 'antd';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import AntdAddressSetForm from 'rev.sdk.js/Components/AntdAddressSetForm';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../../../../data.json';
import * as AppActions from '../../../../AppActions';

function UpdateOrderSection(props) {
  const defaultCheckoutFormSpec = AppActions.getDefaultCheckoutFormSpec();
  const {
    context: {instance, values, setValues},
  } = props;
  const [config] = useOutlet('config');
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = React.useState(false);
  const [actions] = useOutlet('actions');
  const [disabled, setDisabled] = React.useState(false);
  const canEditCheckoutPaymentSubtype =
    instance.items[0].product.name.indexOf('常年會費') > -1 ||
    instance.items[0].name.indexOf('入會費') > -1;

  React.useEffect(() => {
    if (!values) {
      return;
    }
    form.setFieldsValue({});
  }, [form, values]);

  const handleUpdateOrder = React.useCallback(
    async (data) => {
      try {
        actions.setLoading(true);
        const payload = {
          payment_subtype: data.payment_subtype,
          receipt_email_data: {
            ...values.receipt_email_data,
            receipt_title: data.receipt_title,
            gui_number: data.receipt_gui_number,
          },
        };

        await JStorage.updateDocument(
          'checkout',
          {order_number: values.order_number},
          payload,
        );

        if (values.order_id) {
          await JStorage.updateDocument(
            'order',
            {order_id: values.order_id},
            payload,
          );
        }

        setValues((prev) => ({
          ...prev,
          ...payload,
        }));
        setIsDirty(false);
        message.success('更新資訊成功');
      } catch (e) {
        message.error('更新資訊失敗');
      } finally {
        actions.setLoading(false);
      }
    },
    [
      actions,
      setValues,
      values.order_number,
      values.order_id,
      values.receipt_email_data,
    ],
  );

  if (!values) {
    return null;
  }

  return (
    <Wrapper>
      <Form
        layout="horzonital"
        form={form}
        initialValues={{
          receipt_title: values.receipt_email_data?.receipt_title,
          receipt_gui_number: values.receipt_email_data?.gui_number,
          payment_subtype: values.payment_subtype,
        }}
        onFinish={async (data) => {
          await handleUpdateOrder(data);
        }}
        onFinishFailed={() => {}}
        onFieldsChange={(field) => {
          const _isDirty = Object.keys(form.getFieldsValue()).some(
            (key) => form.getFieldsValue()[key] !== values[key],
          );
          setIsDirty(_isDirty);
        }}>
        {values.payment_subtype !== 'offline' && canEditCheckoutPaymentSubtype && (
          <Form.Item label="付款方式" name="payment_subtype">
            <Select disabled={values.payment_subtype === 'offline'}>
              <Select.Option value="credit">信用卡付款</Select.Option>
              <Select.Option value="barcode">條碼繳費</Select.Option>
              <Select.Option value="cvs">超商代碼繳費</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item label="收據抬頭" name="receipt_title">
          <Input placeholder="收據抬頭" />
        </Form.Item>
        <Form.Item label="收據統編" name="receipt_gui_number">
          <Input placeholder="收據統編" />
        </Form.Item>

        <Form.Item>
          <Button
            disabled={!isDirty}
            onClick={form.submit}
            type="primary"
            ghost
            htmlType="button"
            style={{marginRight: 10}}>
            更新資訊
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default UpdateOrderSection;
